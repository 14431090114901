<!--
 * @文档描述: 
 * @author: 宣灵杰
 * @Date: 2024-07-24 14:35:23
 * @LastEditTime: 2024-07-24 17:35:20
 * @LastEditors: 宣灵杰
-->
<template>
  <div class="footer">
    <div class="footer-logo">
      <img src="@/assets/images/beix.png" alt="" />
    </div>
    <!-- <div class="footer-copyright footer-marginTop8">浙ICP备19015556号-1</div> -->
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.footer {
  width: 100%;
  // height: 412px;
  background-color: #f5f5f5;
  color: #fff;
  text-align: center;
  line-height: 100px;
  background-image: url('@/assets/images/footer/footerBg.png');
  padding: 56px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .footer-logo {
    width: 237px;
    height: 112px;
    img {
      width: 237px;
      height: 112px;
    }
  }
  .footer-marginTop35 {
    margin-top: 35px;
  }
  .footer-marginTop8 {
    margin-top: 8px;
  }

  .footer-content {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 7px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .footer-copyright {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 5px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
